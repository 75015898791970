export const menuLinks = (menu_data) => {

    var menu_link = '';

    if(menu_data){
        if(menu_data?.strapi_parent?.slug){
            menu_link = '/'+menu_data?.strapi_parent?.slug.replace(/-ar/g, "")+'/'+menu_data?.slug.replace(/-ar/g, "")+'/';
        } else{
            menu_link = '/'+menu_data.slug.replace(/-ar/g, "")+'/';
        }
    }
    return menu_link;
}